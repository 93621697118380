import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap"
import { gql, useQuery } from "@apollo/client"
import { connect } from "react-redux"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"
import { timeSince } from "../../helpers/timeSince"
import { Link, useParams } from "react-router-dom"

const BasicTable = props => {
  const INTERVAL = 15 * 1000
  const PER_PAGE = 20
  const [currentPage, setCurrentPage] = useState(0)

  const breadcrumbItems = []
  const { error, loading, data, fetchMore } = useQuery(
    gql`
      query ($skip: Int, $first: Int) {
        contributions(
          first: $first
          skip: $skip
          orderBy: timestamp
          orderDirection: desc
        ) {
          id
          blockNumber
          contributorAddress
          hubAddress
          requestID {
						id
					}
          contributorGetsReward
          mageAddress
          fileCID
          metadataCID
          difficulty
          timestamp
        }
      }
    `,
    {
      pollInterval: INTERVAL,
      variables: {
        first: PER_PAGE,
        skip: 0,
      },
    }
  )

  useEffect(() => {
    props.setBreadcrumbItems("Contributions", breadcrumbItems)
  })

  const shortenAddress = address => {
    if (address) return `${address.substr(0, 12)}...`
    return ""
  }
  return (
    <React.Fragment>
      <Row>
        <Col xl={12}>
          <Card>
            <CardBody>
              <CardTitle className="h4">Contributions </CardTitle>
              <div className="table-responsive">
                <Table className="table table-hover mb-0">
                  <thead>
                    <tr>
                      <th>Txn Hash</th>
                      <th>Block</th>
                      <th>Request ID</th>
                      <th>Contributor Address</th>
                      <th>Hub Address</th>
                      <th>Difficulty</th>
                      <th>Timestamp</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan={8} style={{ textAlign: "center" }}>
                          <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      data?.contributions.map(contribution => (
                        <tr>
                          <th scope="row">{shortenAddress(contribution.id)}</th>
                          <td>{contribution.blockNumber}</td>
                          <td>{contribution.requestID.id}</td>
                          <td>
                            {shortenAddress(contribution.contributorAddress)}
                          </td>
                          <td>{shortenAddress(contribution.hubAddress)}</td>
                          <td>{contribution.difficulty}</td>
                          <td>{timeSince(contribution.timestamp)}</td>
													<td>
                            <Link to={`/tx/${contribution.id}`}>
                              View
                            </Link>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
        <nav aria-label="...">
          <Pagination>
            <PaginationItem disabled={currentPage === 0}>
              <PaginationLink
                tabIndex="-1"
                onClick={() => {
                  fetchMore({
                    variables: {
                      first: PER_PAGE,
                      skip: (currentPage - 1) * PER_PAGE,
                    },
                  })
                  setCurrentPage(currentPage - 1)
                }}
              >
                Previous
              </PaginationLink>
            </PaginationItem>
            <PaginationItem active>
              <PaginationLink href="#">
                {currentPage + 1} <span className="sr-only">(currentPage)</span>
              </PaginationLink>
            </PaginationItem>
            <PaginationItem>
              <PaginationLink
                onClick={() => {
                  fetchMore({
                    variables: {
                      first: PER_PAGE,
                      skip: (currentPage + 1) * PER_PAGE,
                    },
                  })
                  setCurrentPage(currentPage + 1)
                }}
              >
                Next
              </PaginationLink>
            </PaginationItem>
          </Pagination>
        </nav>
      </Row>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(BasicTable)
