import React, { useEffect } from "react"

import { Table, Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import { gql, useQuery } from "@apollo/client"

import { connect } from "react-redux"

import TabContainers from "./../components/Tabs/containers"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../store/actions"
import { timeSince } from "../helpers/timeSince"
import { Link } from "react-router-dom"

const Home = props => {
  const INTERVAL = 15 * 1000

  const breadcrumbItems = []
  const { error, loading, data } = useQuery(
    gql`
      query {
        transactions(first: 15) {
          id
          from
          to
          value
          method
          blockNumber
          gasPrice
          gasLimit
          timestamp
        }
        requests(first: 15) {
          id
          txHash
          details(orderBy: timestamp, orderDirection: desc) {
            blockNumber
            timestamp
          }
        }
        contributions(first: 15, orderBy: timestamp, orderDirection: desc) {
          id
          contributorAddress
          difficulty
          timestamp
        }
        confirmations(first: 15, orderBy: timestamp, orderDirection: desc) {
          id
          confirmCount
          timestamp
        }
        requestDetails(first: 15, orderBy: timestamp, orderDirection: desc) {
          id
          title
          timestamp
          totalContributedCount
        }
      }
    `,
    { pollInterval: INTERVAL }
  )

  useEffect(() => {
    props.setBreadcrumbItems(null)
  })

  const shortenAddress = address => {
    if (address) return `${address.substr(0, 12)}...`
    return ""
  }
  return (
    <React.Fragment>
      <Row>
        <Col xl={12}>
          <TabContainers />
        </Col>
      </Row>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  contract: state.Contract.contract,
})

export default connect(mapStateToProps, { setBreadcrumbItems })(Home)
