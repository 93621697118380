import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import ReactApexChart from "react-apexcharts"
import styled from "styled-components"

export const ChartTitle = styled.h2`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: #a3aed0;
`

export const ChartCard = styled(Card)`
  border-radius: 10px;
  height: 95%;
  background: #332c3f;
`

const HelpCol = styled(Col)`
  align-items: center;
  margin-left: 10px;
  margin-bottom: 10px;
  padding-right: 0;
  max-width: unset;
  width: auto;
  flex: 1 1 auto;
`

const ColorBox = styled.div`
  width: 22px;
  height: 22px;
  background-color: ${props => props.color};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`

const Text = styled.span`
  display: flex;
  flex-direction: column;
  width: fit-content;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
`

const PaymentsChart = ({ data, paymentDayValue }) => {
  function range(start, end) {
    return Array(end - start)
      .fill()
      .map((_, idx) => start + idx)
  }
  var options = {
    series: [
      {
        name: "Contributions",
        data: data,
      },
    ],
    annotations: {
      points: [
        {
          x: "",
          seriesIndex: 0,
          label: {
            borderColor: "#775DD0",
            offsetY: 0,
            style: {
              color: "#fff",
              background: "#775DD0",
            },
            text: "",
          },
        },
      ],
    },
    chart: {
      height: 350,
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return `<div class="custom-tooltip">day : ${dataPointIndex} </br> Contributions : ${series[seriesIndex][dataPointIndex]} </div>`
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 3,
        endingShape: "rounded",
        startingShape: "rounded",
        columnWidth: data.length < 5 ? "7%" : "70%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      show: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      labels: {
        show: true,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      categories: range(0, data.length),
    },
    yaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      title: {
        text: "Contributions",
      },
      axisTicks: {
        show: false,
      },
    },
    fill: {
      colors: [
        function ({ dataPointIndex }) {
          if (dataPointIndex == paymentDayValue) {
            return "#34C889"
          }
          if (dataPointIndex == data.length - 1) {
            return "#D3368B"
          }

          if (dataPointIndex < paymentDayValue) {
            return "#A883E0"
          }
          if (
            dataPointIndex > paymentDayValue &&
            dataPointIndex < data.length - 0.9
          ) {
            return "#514A5C"
          }
        },
      ],
    },
  }

  return (
    <Row>
      <Col xs="12">
        <ChartCard>
          <CardBody>
            <ChartTitle className="card-title mb-4">
              Reward Payment Queue
            </ChartTitle>
            <div dir="ltr">
              <ReactApexChart
                options={options}
                series={options.series}
                type="bar"
                height="290"
              />
            </div>
            <Row className="chart-guide text-white">
              {[
                {
                  color: "#A883E0",
                  title: "Paid",
                },
                {
                  color: "#D3368B",
                  title: "Today",
                },
                {
                  color: "#34C889",
                  title: "Paying day",
                },
                {
                  color: "#514A5C",
                  title: "Pending",
                },
              ].map((item, index) => (
                <>
                  <HelpCol key={index}>
                    <Row className="d-flex justify-content-center align-items-center flex-nowrap">
                      <ColorBox color={item.color} />
                      <Text>{item.title}</Text>
                    </Row>
                  </HelpCol>
                </>
              ))}
            </Row>
          </CardBody>
        </ChartCard>
      </Col>
    </Row>
  )
}

export default PaymentsChart
