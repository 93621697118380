import React, { useState, useContext } from "react"
import { Nav, NavItem, NavLink } from "reactstrap"
import classnames from "classnames"
import MainContext from "./../../providers/mainContext"

import { connect } from "react-redux"

const TabsHeader = ({ rest }) => {
  const context = useContext(MainContext)
  const toggle = tab => {
    if (rest.location.pathname !== "/") {
      rest.history.push("/")
    }
    if (tab !== context.activeTab) {
      context.setActiveTab(tab)
    }
  }

  return (
    <Nav tabs className="nav-tabs-custom d-flex flex-nowrap align-items-center">
      <NavItem>
        <NavLink
          className={classnames({
            active: context.activeTab === "Dashboard",
          })}
          onClick={() => toggle("Dashboard")}
        >
          Dashboard
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={classnames({ active: context.activeTab === "Explorer" })}
          onClick={() => toggle("Explorer")}
        >
          Explorer
        </NavLink>
      </NavItem>
    </Nav>
  )
}

const mapStateToProps = state => ({
  contract: state.Contract.contract,
})

export default connect(mapStateToProps, null)(TabsHeader)
