import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { Row, Col, Card, CardBody, FormGroup, Input } from "reactstrap"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

const Wrapper = styled.div`
  margin-bottom: 15px;
`

const ChartCard = styled(Card)`
  border-radius: 10px;
  height: 78px;
  background: #332c3f;
`

const SearchBox = ({ txSearch, setTxSearch, search, setsearch, ...rest }) => {
  return (
    <Wrapper>
      <Col>
        <Card className="custom-card">
          <form
            className="app-search"
            onSubmit={e => {
              e.preventDefault()
              if (txSearch !== "") {
                rest.history.push("/tx/" + txSearch)
              }
            }}
          >
            <CardBody className="pt-0 pb-0">
              <Row className="w-100 d-flex align-items-center justify-content-between flex-row flex-wrap search-wrapper">
                <Col
                  xs={12}
                  sm={12}
                  md={11}
                  lg={11}
                  className="d-flex position-relative col"
                >
                  <FormGroup className="d-flex w-100">
                    <Input
                      value={txSearch}
                      onChange={e => {
                        setTxSearch(e.target.value)
                      }}
                      type="text"
                      className="form-control"
                      placeholder={rest.t("Tx hash") + "..."}
                    />
                  </FormGroup>
                </Col>
                {/* <Col
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                  className="position-relative"
                >
                  <FormGroup className="d-flex w-100">
                    <Input type="select" name="select" className="form-control">
                      <option>Method: All</option>
                      <option>Method: Contribution</option>
                      <option>Method: ...</option>
                    </Input>
                  </FormGroup>
                </Col> */}
                <Col xs={12} sm={12} md={1} lg={1}>
                  <div
                    className="search-icon-wrapper"
                    onClick={() => {
                      if (txSearch !== "") {
                        rest.history.push("/tx/" + txSearch)
                      }
                    }}
                  >
                    <span className="fa fa-search fa-lg text-white"></span>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </form>
        </Card>
      </Col>
    </Wrapper>
  )
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps)(
  withTranslation()(withRouter(SearchBox))
)
