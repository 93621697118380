import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"

import { Table, Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import { gql, useQuery } from "@apollo/client"

import { connect } from "react-redux"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"
import { timeSince } from "../../helpers/timeSince"
import { Link } from "react-router-dom"

const BasicTable = props => {
  const INTERVAL = 15 * 1000
  const breadcrumbItems = []
  const { error, loading, data } = useQuery(
    gql`
      query {
        transactions(first: 15) {
          id
          from
          to
          value
          method
          blockNumber
          gasPrice
          gasLimit
          timestamp
        }
        requests(first: 15) {
          id
          txHash
          details(orderBy: timestamp, orderDirection: desc) {
            blockNumber
            timestamp
          }
        }
        contributions(first: 15, orderBy: timestamp, orderDirection: desc) {
          id
          contributorAddress
          difficulty
          timestamp
        }
        confirmations(first: 15, orderBy: timestamp, orderDirection: desc) {
          id
          confirmCount
          timestamp
        }
        requestDetails(first: 15, orderBy: timestamp, orderDirection: desc) {
          id
          title
          timestamp
          totalContributedCount
        }
      }
    `,
    { pollInterval: INTERVAL }
  )

  useEffect(() => {
    props.setBreadcrumbItems("Zarela Explorer", breadcrumbItems)
  })

  const shortenAddress = address => {
    if (address) return `${address.substr(0, 12)}...`
    return ""
  }
  return (
    <React.Fragment>
      <Row>
        <Col xl={12}>
          <Card>
            <CardBody>
              <CardTitle className="h4">Transactions </CardTitle>
              <div className="table-responsive">
                <Table className="table table-hover mb-0">
                  <thead>
                    <tr>
                      <th>Txn Hash</th>
                      <th>Block</th>
                      <th>Method</th>
                      <th>From</th>
                      <th>To</th>
                      <th>Value</th>
                      <th>Txn Fee</th>
                      <th>Timestamp</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.transactions.map(transaction => (
                      <tr>
                        <th scope="row">{shortenAddress(transaction.id)}</th>
                        <td>{transaction.blockNumber}</td>
                        <td>{transaction.method}</td>
                        <td>{shortenAddress(transaction.from)}</td>
                        <td>{shortenAddress(transaction.to)}</td>
                        <td>{transaction.value}</td>
                        <td>{transaction.gasLimit}</td>
                        <td>{timeSince(transaction.timestamp)}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={8}>
                        <Link to="/transactions">View All</Link>
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xl={6}>
          <Card>
            <CardBody>
              <CardTitle className="h4">Requests </CardTitle>
              <div className="table-responsive">
                <Table className="table table-hover mb-0">
                  <thead>
                    <tr>
                      <th>tx hash</th>
                      <th>request ID</th>
                      <th>block number</th>
                      <th>age</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.requests.map(request => (
                      <tr>
                        <th scope="row">{shortenAddress(request.txHash)}</th>
                        <td>{request.id}</td>
                        <td>{request.details.blockNumber}</td>
                        <td>{timeSince(request.details.timestamp)}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={8}>
                        <Link to="/transactions">View All</Link>
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xl={6}>
          <Card>
            <CardBody>
              <CardTitle className="h4">Contributions </CardTitle>
              <div className="table-responsive">
                <Table className="table table-hover mb-0">
                  <thead>
                    <tr>
                      <th>tx hash</th>
                      <th>difficulty</th>
                      <th>contributor Address</th>
                      <th>age</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.contributions.map(contribution => (
                      <tr>
                        <th scope="row">{`${contribution.id.substr(
                          0,
                          12
                        )}...`}</th>
                        <td>{contribution.difficulty}</td>
                        <td>{`${contribution.contributorAddress.substr(
                          0,
                          12
                        )}...`}</td>
                        <td>{timeSince(contribution.timestamp)}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={8}>
                        <Link to="/transactions">View All</Link>
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(BasicTable)
