import React, { useState, useEffect } from "react"
import { ChainId, Token, Route, Fetcher } from "@uniswap/sdk"

const useBBIT_DAI = () => {
  const [BBIT_DAI, setBBIT_DAI] = useState(0)
  const [isLoading, setLoading] = useState(false)
	const INTERVAL = 20 * 1000;

  useEffect(() => {
    setLoading(true)
    const getPair = async () => {
      const DAI = new Token(
        ChainId.MAINNET,
        "0x6b175474e89094c44da98b954eedeac495271d0f",
        18
      )
      const BBIT = new Token(
        ChainId.MAINNET,
        "0xf67192a8b9f269f23802d9ab94c7875a0abb7aea",
        9
      )

      // note that you may want/need to handle this async code differently,
      // for example if top-level await is not an option
      const pair = await Fetcher.fetchPairData(DAI, BBIT)
      setLoading(false)

      const route = new Route([pair], BBIT)
      setBBIT_DAI(route.midPrice.toSignificant(4))
    }
    let interval = setInterval(() => {
      getPair()
    }, INTERVAL)
    getPair()

    return () => {
      clearInterval(interval)
    }
  }, [])

  return [BBIT_DAI || '-', isLoading]
}

export default useBBIT_DAI
