import React, { useState, useEffect } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import ReactApexChart from "react-apexcharts"
import styled from "styled-components"

export const ChartTitle = styled.h2`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: #a3aed0;
`

export const ChartCard = styled(Card)`
  border-radius: 10px;
  background: #332c3f;
`

const HelpCol = styled(Col)`
  align-items: center;
  margin-left: 10px;
  margin-bottom: 10px;
  max-width: 150px;
`

const ColorBox = styled.div`
  width: 22px;
  height: 22px;
  background-color: ${props => props.color};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`

const Text = styled.span`
  display: flex;
  flex-direction: column;
  width: fit-content;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
`

const Wrapper = styled(Row)`
  position: "relative";
`

const HorizontalLine = styled.div`
  position: absolute;
  width: 95%;
  height: 2px;
  border-bottom: 1px dashed #ccc9d1;
  bottom: calc(50px + ${props => (props.value ? props.value : "50px")});

  &:after {
    content: "Max ${props => (props.title ? props.title : 0)}";
    color: #ccc9d1;
    position: absolute;
    right: 0px;
    bottom: 0px;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
  }
`

const SmallBarChart = ({ data, isMobile }) => {
  function range(start, end) {
    return Array(end - start)
      .fill()
      .map((_, idx) => start + idx)
  }
  var options = {
    series: [
      {
        name: "Max Reward",
        data: data,
      },
    ],
    annotations: {
      points: [
        {
          x: "Bananas",
          seriesIndex: 0,
          label: {
            borderColor: "#775DD0",
            offsetY: 0,
            style: {
              color: "#fff",
              background: "#775DD0",
            },
            text: "Bananas are good",
          },
        },
      ],
    },
    chart: {
      height: 350,
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return `<div class="custom-tooltip">day : ${dataPointIndex} </br> Max Reward : ${series[seriesIndex][dataPointIndex]} </div>`
      },
    },
    plotOptions: {
      bar: {
        borderRadius: isMobile ? 3 : 10,
        endingShape: "rounded",
        startingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      show: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      title: {
        text: "Zarela Day",
      },
      categories: range(0, 21),
    },
    yaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      title: {
        text: "Max Reward",
      },
      axisTicks: {
        show: false,
      },
    },
    fill: {
      colors: ["#A883E0"],
    },
  }

  return (
    <Wrapper>
      <Col xs="12">
        <ChartCard>
          <CardBody>
            <ChartTitle className="card-title mb-4">
              Daily Reward For Each Contributor
            </ChartTitle>
            <div dir="ltr">
              <ReactApexChart
                options={options}
                series={options.series}
                type="bar"
                height="290"
              />
              <HorizontalLine title={50} value="230px"></HorizontalLine>
            </div>
          </CardBody>
        </ChartCard>
      </Col>
    </Wrapper>
  )
}

export default SmallBarChart
