import {
  SET_CONTRACT,
  SET_CONTRACT_SUCCESS,
  SET_CONTRACT_FAILURE,
} from "./actionTypes"

const initialState = {
  contract: null,
  error: null,
  isLoading: false,
}

const contract = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONTRACT:
      state = {
        ...state,
        isLoading: true,
      }
      break
    case SET_CONTRACT_SUCCESS:
      state = {
        ...state,
        contract: action.payload,
      }
      break
    case SET_CONTRACT_FAILURE:
      state = {
        ...state,
        error: action.error,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default contract
