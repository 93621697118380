import React from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import styled from "styled-components"
import { ChartCard, ChartTitle } from "./PaymentsChart"
import C3Chart from "react-c3js"
import "c3/c3.css"

const Title = styled(ChartTitle)`
  color: #fff;
  margin: 0;
`

const SuperTitle = styled(ChartTitle)`
  font-size: ${props => (props.isMobile ? "20px" : "34px")};
  line-height: 42px;
  font-weight: 700;
  color: #fff;
  margin: 0;
  margin-bottom: ${props => (props.isMobile ? "20px" : "0px")};
  white-space: nowrap;
`
const ChartRow = styled(Row)`
  position: relative;
  top: -20px;
  margin-bottom: -30px;
`

const ChartWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;

  & > div {
    width: fit-content;
  }
`

const DonutText = styled.span`
  position: absolute;
  color: #fff;
  top: 40%;
  font-weight: 600;
  font-size: 22px;
`

const DescriptionText = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: #fff;
  margin: 0;
  margin-top: ${props => (props.isMobile ? "20px" : "0px")};
  paddding: 0;
`

const CircleChartRow = styled(Row)`
  margin: 0;
  height: ${props => (props.height ? "190px !important" : "")};
  margin-bottom: ${props => (props.height ? "30px" : "")};
  padding: 0;
`

const CircleChart = ({ distributedPercent, isMobile }) => {
  const data = {
    columns: [
      ["Distributed", distributedPercent !== undefined && distributedPercent],
      ["Remaining", 100 - distributedPercent],
    ],
    label: {
      show: false,
    },
    type: "donut",
  }

  const legend = { show: false }

  const donut = {
    width: 10,
    label: { show: !1 },
  }

  const color = {
    pattern: ["#453D51", "#D3368B"],
  }

  const size = {
    height: 176,
    width: 176,
  }

  const interaction = {
    enabled: true,
  }
  const tooltip = {
    contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
      return `<div class="custom-tooltip">${d[0].name} : ${d[0].value}</div>`
    },
  }

  return (
    <Row className="h-100">
      <Col xs={12}>
        <ChartCard>
          <CardBody>
            <Col className="p-1">
              <Row>
                <Title>Zarela Reward Pool Remaining</Title>
              </Row>
              <Row>
                <SuperTitle isMobile={isMobile}>
                  {distributedPercent && distributedPercent}% Distributed
                </SuperTitle>
              </Row>
              <CircleChartRow
                height
                className="d-flex flex-row-reverse align-items-end"
              >
                {/* <Col md={6}> */}
                <ChartRow>
                  <ChartWrapper>
                    <C3Chart
                      data={data}
                      legend={legend}
                      donut={donut}
                      color={color}
                      size={size}
                      interaction={interaction}
                      tooltip={tooltip}
											unloadBeforeLoad
                    />
                    {distributedPercent !== undefined && (
                      <DonutText>{distributedPercent}%</DonutText>
                    )}
                  </ChartWrapper>
                </ChartRow>
              </CircleChartRow>
              <CircleChartRow className="d-flex flex-row-reverse align-items-end">
                <Row className="p-0 m-0">
                  <DescriptionText isMobile={isMobile}>
                    Total Amount Is 17.000.000
                  </DescriptionText>
                </Row>
                <Row className="p-0 m-0">
                  <DescriptionText>
                    which is going to be Distributed
                  </DescriptionText>
                </Row>
                {/* </Col> */}
              </CircleChartRow>
            </Col>
          </CardBody>
        </ChartCard>
      </Col>
    </Row>
  )
}

export default CircleChart
