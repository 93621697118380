import React, { useContext } from "react"
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
} from "reactstrap"
import { connect } from "react-redux"

import MainContext from "../../../providers/mainContext"
import DashboardTabContainer from "./DashboardTabContainer"
import ExplorerTabContainer from "./ExplorerTabContainer"

const TabsContainers = props => {
  const context = useContext(MainContext)

  return (
    <TabContent activeTab={context.activeTab}>
      <TabPane tabId="Dashboard">
        <DashboardTabContainer />
      </TabPane>
      <TabPane tabId="Explorer">
        <ExplorerTabContainer />
      </TabPane>
    </TabContent>
  )
}

const mapStateToProps = state => ({
  contract: state.Contract.contract,
})

export default connect(mapStateToProps, null)(TabsContainers)
