import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import SearchBox from "./../../SearchBox"
import styled from "styled-components"

import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap"
import { gql, useQuery } from "@apollo/client"

import { connect } from "react-redux"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../../store/actions"
import { timeSince } from "./../../../helpers/timeSince"
import { Link } from "react-router-dom"
import BN from "bignumber.js"

const ChartCard = styled(Card)`
  border-radius: 10px;
  background: #332c3f;
`

const ExplorerTabContainer = props => {
  const INTERVAL = 15 * 1000
  const PER_PAGE = 15
  const DECIMALS = 1000000000
  const [currentPage, setCurrentPage] = useState(0)
  const [txSearch, setTxSearch] = useState("")
  const [search, setsearch] = useState(false)
  const [method, setMethod] = useState("All")

  const breadcrumbItems = []
  const { error, loading, data, fetchMore } = useQuery(
    gql`
      query ($skip: Int, $first: Int) {
        transactions(
          first: $first
          skip: $skip
          orderBy: timestamp
          orderDirection: desc
        ) {
          id
          from
          to
          value
					tokenTransfer
          method
          blockNumber
          gasPrice
          gasLimit
          timestamp
        }
      }
    `,
    {
      pollInterval: INTERVAL,
      variables: {
        first: PER_PAGE,
        skip: 0,
      },
    }
  )

  const shortenAddress = address => {
    if (address) return `${address.substr(0, 12)}...`
    return ""
  }
  return (
    <React.Fragment>
      <SearchBox {...{ txSearch, setTxSearch, search, setsearch }} />
      <Row>
        <Col xl={12}>
          <h4 className="h4 text-white">Transactions </h4>
          <ChartCard>
            <CardBody>
              <div className="table-responsive">
                <Table className="table table-hover mb-0">
                  <thead>
                    <tr>
                      <th>Txn Hash</th>
                      <th>Block</th>
                      <th>Method</th>
                      <th>From</th>
                      <th>To</th>
                      <th>Value</th>
                      <th>Token Transfer (BBIT)</th>
                      <th>Txn Fee</th>
                      <th>Timestamp</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan={8} style={{ textAlign: "center" }}>
                          <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      data?.transactions.map(transaction => (
                        <tr>
                          <th scope="row">{shortenAddress(transaction.id)}</th>
                          <td>{transaction.blockNumber}</td>
                          <td>{transaction.method}</td>
                          <td>{shortenAddress(transaction.from)}</td>
                          <td>{shortenAddress(transaction.to)}</td>
                          <td>{transaction.value}</td>
                          <td>
                            {new BN(transaction.tokenTransfer)
                              .dividedBy(DECIMALS)
                              .toFormat()}
                          </td>
                          <td>{transaction.gasLimit}</td>
                          <td>{timeSince(transaction.timestamp)}</td>
                          <td>
                            <Link to={`/tx/${transaction.id}`}>View</Link>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </ChartCard>
        </Col>
        <nav aria-label="...">
          <Pagination>
            <PaginationItem disabled={currentPage === 0}>
              <PaginationLink
                tabIndex="-1"
                onClick={() => {
                  fetchMore({
                    variables: {
                      skip: (currentPage - 1) * PER_PAGE,
                    },
                  })
                  setCurrentPage(currentPage - 1)
                }}
              >
                Previous
              </PaginationLink>
            </PaginationItem>
            <PaginationItem active>
              <PaginationLink href="#">
                {currentPage + 1} <span className="sr-only">(currentPage)</span>
              </PaginationLink>
            </PaginationItem>
            <PaginationItem>
              <PaginationLink
                onClick={() => {
                  fetchMore({
                    variables: {
                      skip: (currentPage + 1) * PER_PAGE,
                    },
                  })
                  setCurrentPage(currentPage + 1)
                }}
              >
                Next
              </PaginationLink>
            </PaginationItem>
          </Pagination>
        </nav>
      </Row>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(ExplorerTabContainer)
