import React, { useState } from "react"
import MainContext from './mainContext'

const ContextProvider = ({ children }) => {
  const [activeTab, setActiveTab] = useState("Dashboard")

  const value = {
    activeTab,
    setActiveTab,
  }

  return <MainContext.Provider value={value}>{children}</MainContext.Provider>
}

export default ContextProvider