import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import styled from "styled-components"
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardColumns,
  Spinner,
} from "reactstrap"
import { gql, useQuery } from "@apollo/client"
import { connect } from "react-redux"
import BN from "bignumber.js"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"
import { timeSince } from "../../helpers/timeSince"
import { Link, useParams } from "react-router-dom"

const CustomCard = styled(Card)`
  border-radius: 10px;
  background: #332c3f;
`

const BasicTable = props => {
  const INTERVAL = 15 * 1000
  const PER_PAGE = 20
  const DECIMALS = 1000000000
  const [currentPage, setCurrentPage] = useState(0)

  const breadcrumbItems = [
    { title: "Transaction", link: "/" },
    { title: "Details", link: "#" },
  ]

  const { error, loading, data } = useQuery(
    gql`
      query {
        transactions(
          where: {id: "${props.match.params.id}"}
        ) {
          id
          from
          to
          value
					tokenTransfer
          method
          blockNumber
          gasPrice
          gasLimit
          timestamp
        }
				requests(
          where: {txHash: "${props.match.params.id}"}
				) {
					id
					txHash
					details {
						title
						description
						requesterAddress
						angelTokenPay
						laboratoryTokenPay
						totalContributors
						totalContributed
						zpaper
						timestamp
						categories
						totalContributedCount
					}
				}
				contributions(
          where: {id: "${props.match.params.id}"}
				) {
					id
					blockNumber
					contributorAddress
					hubAddress
					requestID {
						id
					}
					contributorGetsReward
					mageAddress
					fileCID
					metadataCID
					difficulty
					timestamp
				}
				confirmations(
          where: {id: "${props.match.params.id}"}
				) {
					id
					blockNumber
					requestID {
						id
					}
					originalIndexes
					confirmCount
					timestamp
				}
      }
    `,
    {
      pollInterval: INTERVAL,
      variables: {
        first: PER_PAGE,
        skip: 0,
      },
    }
  )

  useEffect(() => {
    props.setBreadcrumbItems("", breadcrumbItems)
  })

  // useEffect(() => {
  //   console.log("props", props)
  //   console.log("error", error)
  //   console.log("loading", loading)
  //   console.log("data", data)
  // }, [data, loading, error])

  const shortenAddress = address => {
    if (address) return `${address.substr(0, 12)}...`
    return ""
  }

  const getColumns = data => {
    let transaction, request, contribution, confirmation

    if (data.transactions.length > 0) {
      transaction = (
        <>
          <Row>
            <Col xs={12} md={3}>
              <p className="fw-bold text-white">Tx hash</p>
            </Col>
            <Col className="text-white">{data.transactions[0].id}</Col>
          </Row>
          <Row>
            <Col xs={12} md={3}>
              <p className="fw-bold text-white">From</p>
            </Col>
            <Col className="text-white">{data.transactions[0].from}</Col>
          </Row>
          <Row>
            <Col xs={12} md={3}>
              <p className="fw-bold text-white">To</p>
            </Col>
            <Col className="text-white">{data.transactions[0].to}</Col>
          </Row>
          <Row>
            <Col xs={12} md={3}>
              <p className="fw-bold text-white">Value</p>
            </Col>
            <Col className="text-white">{data.transactions[0].value}</Col>
          </Row>
          <Row>
            <Col xs={12} md={3}>
              <p className="fw-bold text-white">Token Transfer</p>
            </Col>
            <Col className="text-white">
              {new BN(data.transactions[0].tokenTransfer)
                .dividedBy(DECIMALS)
                .toFormat()}{" "}
              BBIT
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={3}>
              <p className="fw-bold text-white">Method</p>
            </Col>
            <Col className="text-white">{data.transactions[0].method}</Col>
          </Row>
          <Row>
            <Col xs={12} md={3}>
              <p className="fw-bold text-white">Block number</p>
            </Col>
            <Col className="text-white">{data.transactions[0].blockNumber}</Col>
          </Row>
          <Row>
            <Col xs={12} md={3}>
              <p className="fw-bold text-white">Gas Price</p>
            </Col>
            <Col className="text-white">{data.transactions[0].gasPrice}</Col>
          </Row>
          <Row>
            <Col xs={12} md={3}>
              <p className="fw-bold text-white">Gas Limit</p>
            </Col>
            <Col className="text-white">{data.transactions[0].gasLimit}</Col>
          </Row>
          <Row>
            <Col xs={12} md={3}>
              <p className="fw-bold text-white">timestamp</p>
            </Col>
            <Col className="text-white">
              {timeSince(data.transactions[0].timestamp)}
            </Col>
          </Row>
        </>
      )
    }
    if (data.contributions.length > 0) {
      contribution = (
        <>
          <Row>
            <Col xs={12} md={3}>
              <p className="fw-bold text-white">requestID</p>
            </Col>
            <Col className="text-white">
              {data.contributions[0].requestID.id}
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={3}>
              <p className="fw-bold text-white">Contributor Address</p>
            </Col>
            <Col className="text-white">
              {data.contributions[0].contributorAddress}
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={3}>
              <p className="fw-bold text-white">Hub Address</p>
            </Col>
            <Col className="text-white">{data.contributions[0].hubAddress}</Col>
          </Row>
          <Row>
            <Col xs={12} md={3}>
              <p className="fw-bold text-white">Mage Address</p>
            </Col>
            <Col className="text-white">
              {data.contributions[0].mageAddress}
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={3}>
              <p className="fw-bold text-white">Response IPFS CID</p>
            </Col>
            <Col className="text-white">{data.contributions[0].fileCID}</Col>
          </Row>
          <Row>
            <Col xs={12} md={3}>
              <p className="fw-bold text-white">Difficulty</p>
            </Col>
            <Col className="text-white">{data.contributions[0].difficulty}</Col>
          </Row>
        </>
      )
    }
    if (data.confirmations.length > 0) {
      confirmation = (
        <>
          <Row>
            <Col xs={12} md={3}>
              <p className="fw-bold text-white">requestID</p>
            </Col>
            <Col className="text-white">
              {data.confirmations[0].requestID.id}
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={3}>
              <p className="fw-bold text-white">confirm count</p>
            </Col>
            <Col className="text-white">
              {data.confirmations[0].confirmCount}
            </Col>
          </Row>
        </>
      )
    }
    if (data.requests.length > 0) {
      request = (
        <>
          <Row>
            <Col xs={12} md={3}>
              <p className="fw-bold text-white">request ID</p>
            </Col>
            <Col className="text-white">{data.requests[0].id}</Col>
          </Row>
          <Row>
            <Col xs={12} md={3}>
              <p className="fw-bold text-white">Requester Address</p>
            </Col>
            <Col className="text-white">
              {data.requests[0].details.requesterAddress}
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={3}>
              <p className="fw-bold text-white">Angel Token Pay</p>
            </Col>
            <Col className="text-white">
              {new BN(data.requests[0].details.angelTokenPay)
                .dividedBy(DECIMALS)
                .toFormat()}{" "}
              BBIT
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={3}>
              <p className="fw-bold text-white">Hub Token Pay</p>
            </Col>
            <Col className="text-white">
              {new BN(data.requests[0].details.laboratoryTokenPay)
                .dividedBy(DECIMALS)
                .toFormat()}{" "}
              BBIT
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={3}>
              <p className="fw-bold text-white">totalContributors</p>
            </Col>
            <Col className="text-white">
              {data.requests[0].details.totalContributors}
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={3}>
              <p className="fw-bold text-white">zpaper</p>
            </Col>
            <Col className="text-white">{data.requests[0].details.zpaper}</Col>
          </Row>
          <Row>
            <Col xs={12} md={3}>
              <p className="fw-bold text-white">timestamp</p>
            </Col>
            <Col className="text-white">
              {timeSince(data.requests[0].details.timestamp)}
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={3}>
              <p className="fw-bold text-white">categories</p>
            </Col>
            <Col className="text-white">
              {data.requests[0].details.categories}
            </Col>
          </Row>
        </>
      )
    }
    return (
      <div>
        <div>{transaction}</div>
        <hr />
        <div className="text-white">
          {contribution || confirmation || request || "no results found"}
        </div>
      </div>
    )
  }

  return (
    <React.Fragment>
      <Row>
        <Col xl={12}>
          <CustomCard>
            <CardBody>
              <CardTitle className="h3 ellipsis-text">
                Tx: {props.match.params.id}{" "}
              </CardTitle>
              <section className="pt-4">
                {loading ? (
                  <Spinner />
                ) : error ? (
                  <div>{error.message}</div>
                ) : (
                  getColumns(data)
                )}
              </section>
            </CardBody>
          </CustomCard>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(BasicTable)
