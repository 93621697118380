import React, { useEffect, useState, useContext } from "react"
import MetaTags from "react-meta-tags"
import { connect } from "react-redux"
import { Row, Col, Modal, Card, CardBody } from "reactstrap"
import metamaskDownload from "../../../assets/images/metamask.png"
import CircleChart from "./../../Charts/CircleChart"
import SmallBarChart from "./../../Charts/SmallBarChart"

// Pages Components
import CustomMiniWidget from "./../../CustomMiniWidget"
import styled from "styled-components"

//Import Action to copy breadcrumb items from local state to redux state
import PaymentsChart from "./../../Charts/PaymentsChart"
import { convertToBBIT } from "../../../helpers/convertToBBIT"
import DataWidget from "./../../../components/VerticalLayout/DataWidget/DataWidget"
import BigNumber from "bignumber.js"
import useBBIT_DAI from "../../../helpers/hooks/useBBIT_DAI"

const DashboardTabContainer = props => {
  const [countDown, setCountDown] = useState(null)
  const [difficulty, setDifficulty] = useState()
  const [todayContributions, setTodayContributions] = useState()
  const [chartData, setChartData] = useState([])
  const [maxRewardData, setMaxRewardData] = useState([])
  const [zarelaDay, setZarelaDay] = useState(null)
  const [distributedPercent, setDistributedPercent] = useState(0)
  const [paymentDayValue, setPaymentDayValue] = useState()
  const [bankBalance, setBankBalance] = useState()
  const [maxReward, setMaxReward] = useState()
  const [hasMetamask, setMetamaskAvailability] = useState(false)
  const [wrongNetwork, setWrongNetwork] = useState(false)
  const [BBIT_DAI, isLoadingBBIT] = useBBIT_DAI()
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  const NETWORK_ID = +process.env.REACT_APP_NETWORK_ID

  useEffect(() => {
    if (!window.ethereum) setMetamaskAvailability(false)
    else setMetamaskAvailability(true)
  }, [])

  useEffect(() => {
    try {
      const metamaskChainId = window.ethereum.request({ method: "eth_chainId" })

      metamaskChainId.then(res => {
        console.log("res", res)
        if (parseInt(res, 16) !== NETWORK_ID) setWrongNetwork(true)
        else setWrongNetwork(false)
      })

      window.ethereum.on("chainChanged", chainId => {
        console.log("changed", chainId)
        if (parseInt(chainId, 16) !== NETWORK_ID) setWrongNetwork(true)
        else {
          setWrongNetwork(false)
          window.location.reload()
        }
      })
    } catch (err) {
      console.log("err", err)
    }
  }, [])

  const isLastDay = (today, zarela_day) => {
    if (zarela_day - today === 1) {
      return true
    } else {
      return false
    }
  }

  const getChartDatas = async (i, props) => {
    // charts data
    try {
      const mainChartResponse = await props.contract.methods
        .dailyContributionsCount(i) // 0 - yesterday
        .call()

      const maxRewardChart = await props.contract.methods
        .dailyRewardPerContributor(i)
        .call()

      if (mainChartResponse) {
        setChartData(prev => [...prev, mainChartResponse])
        if (isLastDay(i, zarelaDay)) {
          props.contract.methods
            .todayContributionsCount() // today
            .call((error, result) => {
              if (!error) {
                setChartData(prev => [...prev, result])
                setTodayContributions(result)
              }
            })
        }
      }
      if (maxRewardChart) {
        setMaxRewardData(prev => [...prev, convertToBBIT(maxRewardChart)])
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const getChartDataAsyncLoop = async (props, zarelaDay) => {
    if (zarelaDay !== null && props.contract) {
      if (zarelaDay === 0) {
        props.contract.methods
          .todayContributionsCount() // today
          .call((error, result) => {
            if (!error) {
              setChartData(prev => [...prev, result])
              setTodayContributions(result)
            }
          })
      } else {
        for (let i = 0; i <= +zarelaDay; i++) {
          try {
            await getChartDatas(i, props)
          } catch (error) {
            console.log("error", error)
          }
        }
      }
    }
  }

  useEffect(() => {
    getChartDataAsyncLoop(props, zarelaDay)
  }, [props.contract, zarelaDay])

  const DataFetch = async () => {
    if (props.contract) {
      /* Zarela Day Counter */
      props.contract.methods
        .zarelaDayCounter()
        .call()
        .then(response => {
          setZarelaDay(+response)
        })
        .catch(err => {
          console.error(err)
        })

      /* countDown 24 Hours */
      props.contract.methods.countDown24Hours().call((error, result) => {
        if (!error) {
          setCountDown(+result * 1000)
        }
      })

      props.contract.methods
        .zarelaDifficultyOfDay()
        .call()
        .then(response => {
          // console.log("zarelaDifficultyOfDay", response)
          setDifficulty(response)
        })
        .catch(err => {
          console.error(err)
        })

      props.contract.methods
        .paymentDay()
        .call()
        .then(response => {
          // console.log("paymentDay", response)
          setPaymentDayValue(response)
        })
        .catch(err => {
          console.error(err)
        })
      props.contract.methods
        .balanceOf(process.env.REACT_APP_ZARELA_CONTRACT_ADDRESS)
        .call()
        .then(response => {
          let total = new BigNumber(17 * 1000000000 * 1000000)
          let balance = new BigNumber(+response)
          let data = total
            .minus(balance)
            .dividedBy(total)
            .times(100)
            .toPrecision(4)

          setDistributedPercent(data)
        })
        .catch(err => {
          console.error(err)
        })

      /**
       * get today bank balance
       */
      props.contract.methods
        .bankBalance()
        .call()
        .then(response => {
          setBankBalance(response)
        })
        .catch(err => {
          console.error(err)
        })

      /**
       * Max reward
       */
      props.contract.methods
        .maxUserDailyReward()
        .call()
        .then(response => {
          setMaxReward(convertToBBIT(response))
        })
        .catch(err => {
          console.error(err)
        })
    }
  }

  useEffect(() => {
    DataFetch()
  }, [props.contract])

  const reports = [
    {
      title: "Zarela Day",
      iconClass: "timer-outline",
      timer: "11:35:45",
      badgecolor: "primary",
      countDown: countDown,
    },
    {
      title: "Max Difficulty",
      iconClass: "cube-outline",
      difficultyValue: 16,
      badgecolor: "simple",
    },
    {
      title: "Current day contributions",
      iconClass: "account-multiple",
      todayContributions: todayContributions,
      badgecolor: "secondary",
    },
  ]
  return (
    <React.Fragment>
      {hasMetamask && wrongNetwork ? (
        <Modal isOpen={true} centered>
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              Wrong Network
            </h5>
          </div>
          <div className="modal-body modal-body-round">
            <div className="metamask">
              <img className="metamask metamask__logo" src={metamaskDownload} />
              <h5>
                Please Switch Your Network To{" "}
                {NETWORK_ID === 1 ? "Mainnet" : "Ropsten"}
              </h5>
            </div>
          </div>
        </Modal>
      ) : null}
      {!hasMetamask ? (
        <Modal
          isOpen={true}
          centered
          onClosed={() => {
            setMetamaskAvailability(false)
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              No Metamask Detected!
            </h5>
          </div>
          <div className="modal-body">
            <div className="metamask">
              <img className="metamask metamask__logo" src={metamaskDownload} />
              <h5>You will need Metamask for this</h5>
              <p>
                to use this dashboard you need to install Metamask extension
              </p>
            </div>
          </div>
          <div className="modal-footer">
            <a
              href={`https://metamask.app.link/dapp/${window.location.host}`}
              target="_blank"
              className="btn btn-primary waves-effect waves-light "
            >
              Download Metamask
            </a>
          </div>
        </Modal>
      ) : null}
      <MetaTags>
        <title>Dashboard | Zarela</title>
      </MetaTags>

      <DataWidget
        bankBalance={bankBalance}
        maxReward={maxReward}
        BBIT={BBIT_DAI}
        isMobile={isMobile}
      />

      {/* {isMobile && (
        <DataWidget
          bankBalance={bankBalance}
          maxReward={maxReward}
          BBIT={BBIT_DAI}
          isMobile={isMobile}
        />
      )} */}
      {/*mimi widgets */}
      <CustomMiniWidget
        difficulty={difficulty}
        todayContributions={todayContributions}
        reports={reports}
        countDown={countDown}
        isMobile={isMobile}
      />

      {/* Payments queue for contributions */}
      <PaymentsChart data={chartData} paymentDayValue={paymentDayValue} />

      <Row>
        <Col md={6}>
          <SmallBarChart
            data={maxRewardData && maxRewardData}
            isMobile={isMobile}
          />
        </Col>
        <Col md={6}>
          <CircleChart
            distributedPercent={distributedPercent}
            isMobile={isMobile}
          />
        </Col>
      </Row>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  contract: state.Contract.contract,
})

export default connect(mapStateToProps, null)(DashboardTabContainer)
