import React from "react"
import styled from "styled-components"
import { Row, Col, Card, CardBody } from "reactstrap"

const Wrapper = styled.div`
  margin-bottom: 15px;
`

const ChartCard = styled(Card)`
  border-radius: 10px;
  height: 95%;
  background: #514a5c;
`

const DataWidget = ({ bankBalance, BBIT, maxReward, isMobile }) => {
  return (
    <Wrapper>
      <Col>
        <ChartCard>
          <CardBody>
            <Row className="justify-content-center align-items-center d-flex flex-row h-100 data-widget-row">
              <Col xs={12} sm={4} md={4} lg={4} className="data-widget-item">
                <Row className="d-flex justify-content-center text-700">
                  Today Bank Balance
                </Row>
                <Row className="d-flex justify-content-center text-white">
                  {bankBalance &&
                    (bankBalance / Math.pow(10, 9)).toLocaleString()}
                </Row>
              </Col>
              <Col
                xs={12}
                sm={4}
                md={4}
                lg={4}
                className="center-column-data data-widget-item"
              >
                <Row className="d-flex justify-content-center text-700">
                  BBIT Current Value
                </Row>
                <Row className="d-flex justify-content-center text-white">
                  {`${BBIT} DAI`}
                </Row>
              </Col>
              <Col xs={12} sm={4} md={4} lg={4} className="data-widget-item">
                <Row className="d-flex justify-content-center text-700">
                  Current Max. Reward
                </Row>
                <Row className="d-flex justify-content-center text-white">
                  {maxReward}
                </Row>
              </Col>
            </Row>
          </CardBody>
        </ChartCard>
      </Col>
    </Wrapper>
  )
}

export default DataWidget
