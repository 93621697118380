import React from "react"
import { Redirect } from "react-router-dom"

import Home from "../pages/Home"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Transaction from "../pages/Dashboard/transaction"
import Contributions from "../pages/Dashboard/contributions"
import Requests from "../pages/Dashboard/requests"

const userRoutes = [
  { path: "/", component: Home },

  // // //profile
  // { path: "/transactions", component: Transactions },
  { path: "/tx/:id", component: Transaction },
  // { path: "/contributions", component: Contributions },
  // { path: "/requests", component: Requests },

  // this route should be at the end of all other routes
  // { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

export { userRoutes }
