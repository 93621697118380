import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import "./i18n"
import { Provider } from "react-redux"
import { Web3ReactProvider } from "@web3-react/core"
import getLibrary from "./helpers/getLibrary"

import ContextProvider from "./providers/ContextProvider"

import store from "./store"

const app = (
  <Provider store={store}>
    <Web3ReactProvider getLibrary={getLibrary}>
      <ContextProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ContextProvider>
    </Web3ReactProvider>
  </Provider>
)

ReactDOM.render(app, document.getElementById("root"))
serviceWorker.unregister()
