import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import getWeb3 from "../../helpers/getWeb3"

import {
  SET_CONTRACT,
  SET_CONTRACT_SUCCESS,
  SET_CONTRACT_FAILURE,
} from "./actionTypes"

function* configureContract() {
  const web3 = yield call(getWeb3)

  const ZarelaContract = yield new web3.eth.Contract(
    JSON.parse(process.env.REACT_APP_ZARELA_CONTRACT_ABI),
    process.env.REACT_APP_ZARELA_CONTRACT_ADDRESS
  )

  yield put({
    type: SET_CONTRACT_SUCCESS,
    payload: ZarelaContract,
  })
}

function* authSaga() {
  yield takeEvery(SET_CONTRACT, configureContract)
}

export default authSaga
