import React from "react"
import { Card, CardBody, Row, Col } from "reactstrap"
import styled from "styled-components"
import Counter from "./counter"

const CustomCard = styled(Card)`
  background: ${props =>
    props.state === "primary"
      ? "#972A55"
      : "#332C3F"};
  border-radius: 20px;
  height: 85px;
`
const Content = styled.div`
  padding-left: ${props => (props.isMobile ? "10px" : "10px")};
`

const Body = styled(CardBody)`
  padding: 0 15px;
`

const Title = styled.h6`
  color: ${props => (props.state === "primary" ? "#fff" : "#fff")};
  margin: 0;
  white-space: nowrap;
  font-size: ${props =>
    props.isMobile ? "13px !important" : "16px !important"};
`
const SubTitle = styled.h2`
  color: ${props => (props.timer ? "#fff" : "#fff")};
  margin: 0;
  height: 30px;
  max-width: 220px;
  display: flex;
  align-items: center;
  justify-content: ${props => (props.isMobile ? "start" : "end")};
`

const IconWrapper = styled.div`
  & > i {
    font-size: 30px;
    width: ${props => (props.isMobile ? "35px" : "49px")};
    height: ${props => (props.isMobile ? "35px" : "49px")};
    line-height: ${props => (props.isMobile ? "35px" : "49px")};
    text-align: center;
    color: ${props => (props.state === "secondary" ? "#fff" : "#fff")};
    border-radius: 50%;
    background: ${props =>
      props.state === "primary"
        ? "#F0CCE0"
        : props.state === "secondary"
        ? "#514A5C"
        : "#514A5C"};
    display: inline-block;
  }
`

const Difficulty = styled.div`
  display: "flex !important";
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  flex-flow: row nowrap;
  justify-content: start;
`

const DifficultySpan = styled.span`
  font-size: ${props => (props.bold ? "24px" : "14px")};
  font-weight: ${props => (props.bold ? "700" : "400")};
  color: ${props => (props.bold ? "#fff" : "#fff")};
  display: inline;
`

const DotSpan = styled.span`
  color: #fff;
`

const CustomMiniWidget = props => {
  return (
    <>
      <Row>
        {props.reports.map((report, key) => (
          <Col xl={4} sm={6} key={key}>
            <CustomCard state={report.badgecolor} className="mini-stat">
              <Body className="card-body d-flex flex-row justify-content-start align-items-center">
                <IconWrapper
                  state={report.badgecolor}
                  isMobile={props.isMobile}
                >
                  <i className={"float-end mdi mdi-" + report.iconClass} />
                </IconWrapper>

                <Content
                  isMobile={props.isMobile}
                  className="flex-grow-1 d-flex flex-row justify-content-between align-items-center"
                >
                  <Row className="justify-content-between align-items-center m-0 p-0 w-100">
                    <Col md={6} className="m-0 p-0">
                      <Title
                        state={report.badgecolor}
                        className="text-uppercase font-size-16"
                        isMobile={props.isMobile}
                      >
                        {report.title}
                      </Title>
                    </Col>
                    <Col
                      md={6}
                      className={
                        props.isMobile
                          ? "justify-content-between align-items-center m-0 p-0"
                          : "justify-content-end align-items-center m-0 p-0"
                      }
                    >
                      {report.countDown && (
                        <SubTitle isMobile={props.isMobile} timer>
                          {props.countDown && (
                            <Counter
                              zarelaInitDate={props.countDown}
                              isMobile={props.isMobile}
                            />
                          )}
                        </SubTitle>
                      )}

                      {report.difficultyValue && (
                        <Difficulty>
                          {[1, 2, 4, 8, 16, 32, 128].map(item => (
                            <>
                              <DifficultySpan
                                bold={
                                  props.difficulty && +props.difficulty === item
                                }
                              >
                                {item}
                              </DifficultySpan>
                              {item !== 128 && <DotSpan> . </DotSpan>}
                            </>
                          ))}
                        </Difficulty>
                      )}
                      {report.todayContributions && (
                        <SubTitle isMobile={props.isMobile}>
                          {report.todayContributions}
                        </SubTitle>
                      )}
                    </Col>
                  </Row>
                </Content>
              </Body>
            </CustomCard>
          </Col>
        ))}
      </Row>
    </>
  )
}

export default CustomMiniWidget
