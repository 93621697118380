import React from 'react';
import styled from 'styled-components';
import { useStopwatch } from 'react-timer-hook';

const Wrapper = styled.div`
	flex: 3 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
`;

const Row = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
`;

const Title = styled.div`
	font-weight: bold;
	font-size: 28px;
	line-height: 30px;
	color: white;
	margin-bottom: 40px;
`;

const Segment = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	min-width: ${(props) => (props.isMobile ? '0' : '40px')};
`;

const Digit = styled.div`
	font-family: Poppins;
	font-weight: normal;
	font-size: ${(props) => (props.isMobile ? '20px' : '32px')};
	line-height: ${(props) => (props.isMobile ? '20px' : '32px')};
	color: #ffffff;
`;

const Label = styled.div`
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	text-align: justify;
	margin-top: 10px;
	color: #081985;
`;

const Colon = styled.div`
	font-size: 14px;

	&::after {
		display: block;
		content: ':';
		color: white;
		font-size: ${(props) => (props.isMobile ? '20px' : '32px')};
		margin: 0 5px;
		line-height: 1;
	}
`;
const FallBackMessage = styled.p`
	font-size: 14px;
	margin-top: 14px;
`;

function getDifferenceInMSeconds(date1, date2) {
	const diffInMs = Math.abs(date2 - date1);
	return diffInMs;
}

const TimerCountDown = ({ countdown }) => {
	const stopWatch = useStopwatch({
		autoStart: true,
		offsetTimestamp: new Date().setSeconds(
			new Date().getSeconds() + countdown / 1000
		),
	});
	const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

	return (
		<>
			<Row>
				<>
					<Segment isMobile={isMobile}>
						<Digit isMobile={isMobile}>
							{+stopWatch.days * 24 + +stopWatch.hours}
						</Digit>
					</Segment>
					<Colon isMobile={isMobile} />
				</>
				<>
					<Segment isMobile={isMobile}>
						<Digit isMobile={isMobile}>{stopWatch.minutes}</Digit>
					</Segment>
					<Colon isMobile={isMobile} />
				</>
				<Segment isMobile={isMobile}>
					<Digit isMobile={isMobile}>{stopWatch.seconds}</Digit>
				</Segment>
			</Row>
		</>
	);
};

export default TimerCountDown;
