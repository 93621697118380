import React, { useState, useEffect } from "react"
import styled from "styled-components"
import TimerCountDown from "./TimerCountDown"

const Wrapper = styled.div`
  ${"" /* ${maxWidthWrapper}; */}
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  height: 100%;
`

function getDifferenceInMSeconds(date1, date2) {
  const diffInMs = Math.abs(date2 - date1)
  return diffInMs
}

const Counter = ({ zarelaInitDate }) => {
  const [lastContributionTimeStamp, setLastContributionTimeStamp] = useState(0)

  useEffect(() => {
    if (zarelaInitDate !== null) {
      const diff = getDifferenceInMSeconds(zarelaInitDate , new Date().getTime());
      setLastContributionTimeStamp(diff)
    }
  }, [zarelaInitDate])

  return (
    <Wrapper>{lastContributionTimeStamp !== 0 && <TimerCountDown countdown={lastContributionTimeStamp} />}</Wrapper>
  )
}

export default Counter
