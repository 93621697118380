import { ApolloClient, InMemoryCache } from "@apollo/client"
import { offsetLimitPagination } from "@apollo/client/utilities"

export const client = new ApolloClient({
  uri: process.env.REACT_APP_EXPLORER_SUBGRAPH_URL,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          transactions: offsetLimitPagination(),
          confirmations: offsetLimitPagination(),
          contributions: offsetLimitPagination(),
          requests: offsetLimitPagination(),
        },
      },
    },
  }),
})
