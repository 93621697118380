import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Row, Col } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import TabsHeader from "./../Tabs/TabsHeader"
import useBBIT_DAI from "helpers/hooks/useBBIT_DAI"
import { convertToBBIT } from "helpers/convertToBBIT"

// import megamenuImg from "../../assets/images/megamenu-img.png"
import logo from "../../assets/images/logo.png"
import logoLightPng from "../../assets/images/logo-light.png"

//i18n
import { withTranslation } from "react-i18next"

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions"

const Header = ({ ...rest }) => {
  const [zarelaDay, setZarelaDay] = useState()
  const [bankBalance, setBankBalance] = useState()
  const [maxReward, setMaxReward] = useState()
  const [zarelaDate, setZarelaDate] = useState()
  const [BBIT_DAI, isLoadingBBIT] = useBBIT_DAI()

  useEffect(() => {
    if (zarelaDay !== 0) {
      var date = new Date()
      let result =
        date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
      if (result.split("/")[0] !== "NaN") {
        setZarelaDate(result.split("/").join("."))
      }
    }
  }, [zarelaDay])

  useEffect(() => {
    if (rest.contract) {
      /**
       * get Zarela Day
       */
      rest.contract.methods
        .zarelaDayCounter()
        .call()
        .then(response => {
          setZarelaDay(response)
        })
        .catch(err => {
          console.error(err)
        })

      /**
       * get today bank balance
       */
      rest.contract.methods
        .bankBalance()
        .call()
        .then(response => {
          setBankBalance(response)
        })
        .catch(err => {
          console.error(err)
        })

      /**
       * Max reward
       */
      rest.contract.methods
        .maxUserDailyReward()
        .call()
        .then(response => {
          setMaxReward(convertToBBIT(response))
        })
        .catch(err => {
          console.error(err)
        })
    }
  }, [rest.contract])

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="w-100 header-main-row">
            <Col className="navbar-brand-box flex-grow-0 ">
              <Link to="/" className="logo logo-light">
                <span>
                  <img src={logo} alt="" height="24" />
                </span>
              </Link>
            </Col>
            <Col
              xs={5}
              className="d-flex flex-column justify-content-center align-items-start header-tabs-lg"
            >
              <TabsHeader {...{ rest }} />
            </Col>

            <Col
              xs={5}
              className="d-flex flex-column justify-content-center align-items-end"
            >
              <Row>
                <span className="text-white">{zarelaDate ?? "--.--.--"}</span>
              </Row>
              <Row>
                <span className="text-white">Zarela Day : {zarelaDay}th</span>
              </Row>
            </Col>
          </div>
        </div>
        <div className="subheader-sm">
          <Col
            md={5}
            className="d-flex flex-column justify-content-center align-items-start"
          >
            <TabsHeader {...{ rest }} />
          </Col>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

// const mapStatetoProps = state => {
//   const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
//     state.Layout
//   return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
// }
const mapStatetoProps = state => ({
  contract: state.Contract.contract,
})

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(withRouter(Header)))
